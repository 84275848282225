import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IWrBackupItemResponse } from '@/api/types'
import type {
  ArchiveState,
  IArchiveItems,
  ArchiveLocalMeta,
  IArchivePathParams,
  IDirectoryArchiveMeta,
  IDirectoryArchiveOperation,
  IWrLocaleArchiveItemResponse,
} from '@/store/modules/client/archive/types'

type ArchiveTree = GetterTree<ArchiveState, RootState>

import { getWrIcons } from '@/helpers/wr-icons'
import { getTotalSize, getLastUpdatedDate } from '@/helpers/storage'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchiveTree = {
  /**
   * archiveDisplayName
   * ? Отображаемое имя архива
   *
   * @param {ArchiveState} state data
   * @returns {string} отображаемое имя архива
   */
  archiveDisplayName: (state: ArchiveState): string => {
    return state.currentArchive?.display_name ?? ''
  },

  /**
   * currentArchive
   * ? Содержимое текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrLocaleArchiveItemResponse | null } содержимое текущего архива
   */
  currentArchive: (
    state: ArchiveState
  ): IWrLocaleArchiveItemResponse | null => {
    if (state.currentArchive === null) {
      return null
    }

    const { size, name, subtype, updated_at, display_name } =
      state.currentArchive

    const params = { name, versions: state.currentArchiveVersions }
    const totalSize = getTotalSize(params)
    const lastUpdatedDate = getLastUpdatedDate(params)

    return {
      ...state.currentArchive,
      icon: getWrIcons(subtype),
      formatSize: getFormattedSize(size),
      formatTotalArchiveSize: getFormattedSize(totalSize),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatLastUpdatedArchiveDateTime: getFormattedDate(lastUpdatedDate, true),
    }
  },

  /**
   * archiveTree
   * ? Дерево директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveOperation>} дерево директорий архива
   */
  archiveTree: (state: ArchiveState): Partial<IDirectoryArchiveOperation> => {
    return state.archiveTree
  },

  /**
   * archiveTreeMeta
   * ? Дерево мета данных директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveMeta>} дерево мета данных директорий архива
   */
  archiveTreeMeta: (state: ArchiveState): Partial<IDirectoryArchiveMeta> => {
    return state.archiveTreeMeta
  },

  /**
   * currentView
   * ? Текущий вид архивов
   *
   * @param {ArchiveState} state data
   * @returns {string} текущий вид архивов
   */
  currentView: (state: ArchiveState): string => {
    return state.currentView
  },

  /**
   * currentArchiveFolder
   * ? Текущая папка архива
   *
   * @param {ArchiveState} state data
   * @returns {string} текущая папка архива
   */
  currentArchiveFolder: (state: ArchiveState): string => {
    return state.currentFolder
  },

  /**
   * currentArchiveName
   * ? Имя текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {string} имя текущего архива
   */
  currentArchiveName: (state: ArchiveState): string => {
    return state.currentArchiveName
  },

  /**
   * currentArchiveVersions
   * ? Список версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrBackupItemResponse[]} список версий текущего архива
   */
  currentArchiveVersions: (state: ArchiveState): IWrBackupItemResponse[] => {
    return state.currentArchiveVersions
  },

  /**
   * currentArchiveDirectory
   * ? Текущая (выбранная) директория архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория архива
   */
  currentArchiveDirectory: (
    state: ArchiveState,
    getters
  ): IWrBackupItemResponse[] => {
    const backupPath = getters.currentArchivePathParams.backupPath

    if (!(backupPath in state.archiveTree)) {
      return []
    }

    const archiveItems = state.archiveTree[backupPath]?.items ?? []

    return archiveItems.map((archive) => {
      const { size, type, subtype, updated_at } = archive

      return {
        ...archive,
        icon: getWrIcons(subtype ?? type),
        formatSize: getFormattedSize(size),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {ArchiveState} state data
   * @returns {Partial<string[]>} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: ArchiveState): Partial<string[]> => {
    return state.treeDirectoryPath
  },

  /**
   * currentDirectoryArchiveItem
   * ? Текущий элемент директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IArchiveItems} текущий элемент директории архива
   */
  currentDirectoryArchiveItem: (
    state: ArchiveState,
    getters
  ): IArchiveItems => {
    return state.archiveTree[getters.currentArchivePathParams.backupPath] ?? {}
  },

  /**
   * currentDirectoryArchiveMeta
   * ? Текущие мета данные директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {ArchiveLocalMeta} текущие мета данные директории архива
   */
  currentDirectoryArchiveMeta: (
    state: ArchiveState,
    getters
  ): ArchiveLocalMeta => {
    return (
      state.archiveTreeMeta[getters.currentArchivePathParams.backupPath] ??
      state.defaultArchiveMeta
    )
  },

  /**
   * currentArchivePathParams
   * ? Параметры текущего пути директории
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {IArchivePathParams} параметры текущего пути директории
   */
  currentArchivePathParams: (
    state: ArchiveState,
    getters,
    rootState
  ): IArchivePathParams => {
    const {
      currentArchiveName,
      treeDirectoryPath,
      currentArchiveFolder,
      archiveDisplayName,
    } = getters

    const getArrayPath = () => {
      const pathMatch = rootState.nav?.route?.params?.pathMatch
      const localArrayPath = treeDirectoryPath
      const queryArrayPath =
        typeof pathMatch === 'string' ? pathMatch.split('/') : []

      return localArrayPath.length === 0 &&
        currentArchiveFolder !== archiveDisplayName
        ? queryArrayPath
        : localArrayPath
    }

    const backup = currentArchiveName
    const currentArrayPath = getArrayPath()
    const path = currentArrayPath.join('/')
    const directories = [archiveDisplayName, ...currentArrayPath]
    const folder =
      currentArrayPath[currentArrayPath.length - 1] ?? archiveDisplayName
    const backupPath = [backup, ...currentArrayPath].join('/')

    return { path, backup, folder, backupPath, directories }
  },

  /**
   * defaultArchiveMeta
   * ? Дефолтные мета данные архива
   *
   * @param {ArchiveState} state data
   * @returns {ArchiveLocalMeta} дефолтные мета данные архива
   */
  defaultArchiveMeta: (state: ArchiveState): ArchiveLocalMeta => {
    return state.defaultArchiveMeta
  },

  /**
   * isEncryptedArchive
   * ? Признак зашифрованного архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} признак зашифрованного архива
   */
  isEncryptedArchive: (state: ArchiveState): boolean => {
    return Boolean(state.currentArchive?.encrypted)
  },

  /**
   * isLoadingCurrentArchive
   * ? Процесс загрузки текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего архива
   */
  isLoadingCurrentArchive: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchive
  },

  /**
   * isLoadingUrlArchiveImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlArchiveImage: (state: ArchiveState): boolean => {
    return state.isLoadingUrlArchiveImage
  },

  /**
   * isLoadingFirstDirectoryArchiveItems
   * ? Процесс загрузки первых директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки первых директорий архива
   */
  isLoadingFirstDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingFirstDirectoryArchiveItems
  },

  /**
   * isLoadingNextDirectoryArchiveItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingNextDirectoryArchiveItems
  },

  /**
   * isLoadingCurrentArchiveFileDownload
   * ? Процесс скачивания выбранного файла архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс скачивания выбранного файла архива
   */
  isLoadingCurrentArchiveFileDownload: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveFileDownload
  },
}
