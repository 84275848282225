import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IArchivesState,
  IArchiveVersions,
  ArchivesLocalMeta,
} from '@/store/modules/client/archives/types'
import type { IWrBackupItemResponse } from '@/api/types'

type ArchivesTree = GetterTree<IArchivesState, RootState>

import { getWrIcons, ICON_TYPE_LIST } from '@/helpers/wr-icons'
import { getTotalSize, getLastUpdatedDate } from '@/helpers/storage'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchivesTree = {
  /**
   * hasArchives
   * ? Признак наличия архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} признак наличия архивов
   */
  hasArchives: (state: IArchivesState): boolean => {
    return state.archives.length > 0
  },

  /**
   * archives
   * ? Список архивов
   *
   * @param {IArchivesState} state data
   * @returns {IWrBackupItemResponse[]} список архивов
   */
  archives: (state: IArchivesState): IWrBackupItemResponse[] => {
    return state.archives.map((archive: IWrBackupItemResponse) => {
      const { name, display_name, subtype } = archive
      const [decodedName] = decodeURIComponent(name).split('/')
      const params = { name, versions: state.versions }
      const totalSize = getTotalSize(params)
      const lastUpdatedDate = getLastUpdatedDate(params)
      // Пока что нет других признаков, для проверки того, что версия полноценно загружена. Если имя пустое, блокируем архив.
      const hasArchiveVersion = Boolean(state.versions[name]?.at(-1)?.name)

      return {
        ...archive,
        hasVersion: hasArchiveVersion,
        formatDisplayName: display_name,
        icon: getWrIcons(subtype, ICON_TYPE_LIST),
        formatName: getFormattedSimpleValue(decodedName),
        formatTotalArchiveSize: getFormattedSize(totalSize),
        formatCreatedArchiveDate: getFormattedDate(lastUpdatedDate),
        formatLastUpdatedArchiveDateTime: getFormattedDate(
          lastUpdatedDate,
          true
        ),
      }
    })
  },

  /**
   * archiveListVersions
   * ? Список всех архивов со всеми версиями
   *
   * @param {IArchivesState} state data
   * @returns {IArchiveVersions} список всех архивов со всеми версиями
   */
  archiveListVersions: (state: IArchivesState): IArchiveVersions => {
    return state.versions
  },

  /**
   * currentArchivesMeta
   * ? Текущие мета списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {ArchivesLocalMeta} текущие мета списка архивов
   */
  currentArchivesMeta: (state: IArchivesState): ArchivesLocalMeta => {
    return state.currentArchivesMeta
  },

  /**
   * isLoadingArchives
   * ? Процесс загрузки списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки списка архивов
   */
  isLoadingArchives: (state: IArchivesState): boolean => {
    return state.isLoadingArchives
  },

  /**
   * isLoadingArchiveDelete
   * ? Процесс удаления архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс удаления архива
   */
  isLoadingArchiveDelete: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveDelete
  },

  /**
   * isLoadingArchiveVersion
   * ? Процесс загрузки версий архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки версий архива
   */
  isLoadingArchiveVersion: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveVersion
  },
}
