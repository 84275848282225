import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  BackupsState,
  BackupVersions,
  BackupsLocalMeta,
} from '@/store/modules/client/backups/types'
import type { IWrBackupItemResponse } from '@/api/types'

type BackupsTree = GetterTree<BackupsState, RootState>

import { App } from '@/main'
import { getTotalSize, getLastUpdatedDate } from '@/helpers/storage'
import { getWrIcons, ICON_TYPE_LIST } from '@/helpers/wr-icons'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: BackupsTree = {
  /**
   * hasBackups
   * ? Признак наличия резервных копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} признак наличия резервных копий
   */
  hasBackups: (state: BackupsState): boolean => {
    return state.backups.length > 0
  },

  /**
   * backups
   * ? Список резервных копий
   *
   * @param {BackupsState} state data
   * @returns {Array<IWrBackupItemResponse>} список резервных копий
   */
  backups: (state: BackupsState): Array<IWrBackupItemResponse> => {
    return state.backups.map((backup: IWrBackupItemResponse) => {
      const { name, subtype, display_name, device } = backup
      const params = { name, versions: state.versions }
      const totalSize = getTotalSize(params)
      const lastUpdatedDate = getLastUpdatedDate(params)

      return {
        ...backup,
        device:
          device || App.$i18n.t('page.backup.device.not-recognized').toString(),
        icon: getWrIcons(subtype, ICON_TYPE_LIST),
        formatDisplayName: getFormattedSimpleValue(display_name),
        amountVersion: state.versions[name]?.length ?? 0,
        formatTotalBackupSize: getFormattedSize(totalSize),
        formatLastBackupCreatedDate: getFormattedDate(lastUpdatedDate),
        formatLastBackupUpdatedDateTime: getFormattedDate(
          lastUpdatedDate,
          true
        ),
      }
    })
  },

  /**
   * backupListVersions
   * ? Список всех бэкапов со всеми версиями
   *
   * @param {BackupsState} state data
   * @returns {BackupVersions} список всех бэкапов со всеми версиями
   */
  backupListVersions: (state: BackupsState): BackupVersions => {
    return state.versions
  },

  /**
   * currentBackupsMeta
   * ? Текущие мета списка бэкапов
   *
   * @param {BackupsState} state data
   * @returns {BackupsLocalMeta} текущие мета списка бэкапов
   */
  currentBackupsMeta: (state: BackupsState): BackupsLocalMeta => {
    return state.currentBackupsMeta
  },

  /**
   * isLoadingBackups
   * ? Процесс загрузки списка резервных копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс загрузки списка резервных копий
   */
  isLoadingBackups: (state: BackupsState): boolean => {
    return state.isLoadingBackups
  },

  /**
   * isLoadingBackupDelete
   * ? Процесс удаления резервной копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс удаления резервной копий
   */
  isLoadingBackupDelete: (state: BackupsState): boolean => {
    return state.isLoadingBackupDelete
  },

  /**
   * isLoadingBackupDownload
   * ? Процесс скачивания резервной копии
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс скачивания резервной копи
   */
  isLoadingBackupDownload: (state: BackupsState): boolean => {
    return state.isLoadingBackupDownload
  },

  /**
   * groupLoadingBackupVersions
   * ? Процесс множественной загрузки версий бэкапов
   *
   * @param {BackupsState} state data
   * @returns {Function} процесс множественной загрузки версий бэкапов
   */
  groupLoadingBackupVersions: (
    state: BackupsState
  ): ((name: string) => boolean) => {
    return (name) => {
      return state.groupLoadingBackupVersions[name]
    }
  },
}
