import type {
  TAccount,
  ActivatePayload,
  ICompanyResponse,
  StorageInfoResponse,
  TLinkCompanyPayload,
  AccountDeletePayload,
  IUserAccountsPayload,
  IUserAccountsResponse,
  IRegisterAccountResponse,
  IPersonalRegisterAccountPayload,
  IBusinessRegisterAccountPayload,
} from '@/api/services/account/types'
import type { ICompany, TCountry } from '@/api/types'

import axios from '@/api/axios'
import { COUNTRIES } from '@/config/constants'

export default {
  /** ==== Account ==== */

  /**
   * accountActivateWithForm
   * ? Активация аккаунта с помощью формы (регистрация с внешнего ресурса)
   *
   * @param {ActivatePayload} payload форма для активации аккаунта
   * @returns {Promise<void>}
   */
  accountActivateWithForm(payload: ActivatePayload): Promise<void> {
    return axios.post('/api/account/v1/account:activate_and_complete', payload)
  },

  /**
   * registerPersonalAccount
   * ? Регистрация клиентского аккаунта
   *
   * @param {IPersonalRegisterAccountPayload} payload форма для регистрации клиентского аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerPersonalAccount(
    payload: IPersonalRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/customer_accounts', payload)
  },

  /**
   * registerBusinessAccount
   * ? Регистрация бизнес аккаунта
   *
   * @param {IBusinessRegisterAccountPayload} payload форма для регистрации бизнес аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор бизнес аккаунта
   */
  registerBusinessAccount(
    payload: IBusinessRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/business_accounts', payload)
  },

  /**
   * getCurrentAccount
   * ? Получить текущий аккаунт
   *
   * @returns {Promise<TAccount>} свойства аккаунта
   */
  getCurrentAccount(): Promise<TAccount> {
    return axios.get('/api/account/v1/accounts/me')
  },

  /**
   * getUserAccount
   * ? Получить конкретный аккаунт пользователя
   *
   * @param {string} id идентификатор пользователя
   * @returns {Promise<TAccount>} конкретный аккаунт пользователя
   */
  getUserAccount(id: string): Promise<TAccount> {
    return axios.get(`/api/account/v1/accounts/${id}`)
  },

  /**
   * getUserAccounts
   * ? Получить список аккаунтов пользователей
   *
   * @param {IUserAccountsPayload} params параметры конфигурирования списка пользователей
   * @returns {Promise<IUserAccountsResponse>} список аккаунтов пользователей
   */
  getUserAccounts(
    params: IUserAccountsPayload
  ): Promise<IUserAccountsResponse> {
    return axios.get(`/api/account/v1/accounts`, { params })
  },

  /**
   * getUrlWebRestore
   * ? Получить актуальный url Web Restore
   *
   * @returns {Promise<StorageInfoResponse>} url web restore
   */
  getUrlWebRestore(): Promise<StorageInfoResponse> {
    return axios.get('/api/account/v1/storage:info')
  },

  /**
   * updateAccount
   * ? Обновить данные о пользователе
   *
   * @param {string} accountId идентификатор пользователя
   * @param {Partial<TAccount>} account форма изменения информации о пользователе
   * @returns {Promise<void>}
   */
  updateAccount(accountId: string, account: Partial<TAccount>): Promise<void> {
    return axios.put(`/api/account/v1/accounts/${accountId}`, account)
  },

  /**
   * switchSubscribe
   * ? Подписаться/отписаться на рассылку
   *
   * @param {string} accountId идентификатор пользователя
   * @param {boolean} enabled признак подписки на рассылку
   * @returns {Promise<void>}
   */
  switchSubscribe(accountId: string, enabled: boolean): Promise<void> {
    return axios.put(
      `/api/account/v1/accounts/${accountId}/marketing_notifications`,
      { enabled }
    )
  },

  /**
   * deleteAccount
   * ? Удалить аккаунт
   *
   * @param {AccountDeletePayload} payload форма удаления профиля
   * @returns {Promise<void>}
   */
  deleteAccount(payload: AccountDeletePayload): Promise<void> {
    const { account_id, data } = payload
    return axios.delete(`/api/account/v1/accounts/${account_id}`, {
      data,
    })
  },

  /**
   * deleteAccounts
   * ? Удалить список аккаунтов
   *
   * @param {string} ids идентификаторы аккаунтов (ids в виде строки через запятую)
   * @returns {Promise<void>}
   */
  deleteAccounts(ids: string): Promise<void> {
    const params = { ids }
    return axios.delete('/api/account/v1/accounts', { params })
  },

  /**
   * getCompany
   * ? Возвращает компанию по её идентификатору
   *
   * @param {string} id идентификатор компании
   * @param {string} token токен компании
   * @returns {Promise<ICompany>} данные компании
   */
  getCompany(id: string, token: string): Promise<ICompany> {
    return axios
      .get<ICompanyResponse, ICompanyResponse>(
        `/api/account/v1/companies/${id}/by_token`,
        {
          params: { token },
        }
      )
      .then((response) => ({
        ...response,
        country: response.country_code.toLowerCase() as TCountry,
        type: response.organization_type,
        unp:
          response.country_code?.toLowerCase() === COUNTRIES.BY
            ? response.inn
            : '',
      }))
  },

  /**
   * linkCompany
   * ? Привязывает пользователя к компании
   *
   * @param {string} accountId идентификатор аккаунта
   * @param {TLinkCompanyPayload} data данные для привязки компании
   * @returns {Promise<void>} Возвращает ошибку OPERATION_NOT_AVAILABLE, если аккаунт уже привязан к другой компании
   */
  linkCompany(accountId: string, data: TLinkCompanyPayload): Promise<void> {
    return axios.post(
      `/api/account/v1/accounts/${accountId}/link_company`,
      data
    )
  },
}
