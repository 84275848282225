import type { MutationTree } from 'vuex'
import type {
  MetaResponse,
  IExternalDownloadUrl,
  IExternalLicenseResponse,
} from '@/api/types'
import type { IExternalProductsState } from '@/store/modules/business/external-products/types'

type TExternalProductsTree = MutationTree<IExternalProductsState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TExternalProductsTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_EXTERNAL_LICENSES
   * ? Установить внешние лицензии
   *
   * @param {IExternalProductsState} state data
   * @param {IExternalLicenseResponse[]} licenses внешние лицензии
   * @returns {void}
   */
  SET_EXTERNAL_LICENSES: (
    state: IExternalProductsState,
    licenses: IExternalLicenseResponse[]
  ): void => {
    state.externalLicenses = licenses
  },

  /**
   * SET_HAS_TRIAL_LICENSE
   * ? Определить наличие триальной лицензии
   *
   * @param {IExternalProductsState} state data
   * @param {IExternalLicenseResponse[]} licenses внешние лицензии
   * @returns {void}
   */
  SET_HAS_TRIAL_LICENSE: (
    state: IExternalProductsState,
    licenses: IExternalLicenseResponse[]
  ): void => {
    state.hasTrialLicenses = licenses.some((license) => license.is_trial)
  },

  /**
   * SET_HAS_LICENSES
   * ? Определить наличие лицензий
   *
   * @param {IExternalProductsState} state data
   * @param {IExternalLicenseResponse[]} licenses внешние лицензии
   * @returns {void}
   */
  SET_HAS_EXTERNAL_LICENSES: (
    state: IExternalProductsState,
    licenses: IExternalLicenseResponse[]
  ): void => {
    state.hasExternalLicenses = licenses.length > 0
  },

  /**
   * SET_EXTERNAL_LICENSES_META
   * ? Установить мета данные внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @param {MetaResponse} meta мета данные внешних лицензий
   * @returns {void}
   */
  SET_EXTERNAL_LICENSES_META: (
    state: IExternalProductsState,
    meta: MetaResponse
  ): void => {
    state.externalLicensesMeta = meta
  },

  /**
   * SET_DOWNLOAD_URLS
   * ? Установить список ссылок на скачивание дистрибутивов
   *
   * @param {IExternalProductsState} state data
   * @param {IExternalDownloadUrl[]} urls список ссылок на скачивание дистрибутивов
   * @returns {void}
   */
  SET_DOWNLOAD_URLS: (
    state: IExternalProductsState,
    urls: IExternalDownloadUrl[]
  ): void => {
    state.downloadUrls = urls
  },
}
