import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IBackupDeletePayload } from '@/api/types'
import type {
  IArchivesState,
  ArchivesLocalMeta,
} from '@/store/modules/client/archives/types'

type ArchivesTree = ActionTree<IArchivesState, RootState>
type ArchivesContext = ActionContext<IArchivesState, RootState>

import api from '@/api'
import { API_ERROR } from '@/config/constants'

export const actions: ArchivesTree = {
  /**
   * fetchArchives
   * ? Извлечь список архивов
   *
   * @param {ArchivesContext} ctx context
   * @param {ArchivesLocalMeta} params мета данные запроса
   * @returns {Promise<void>}
   */
  fetchArchives: (
    { commit, dispatch }: ArchivesContext,
    params: ArchivesLocalMeta
  ): Promise<void> => {
    commit('SET_ARCHIVES_CURRENT_META', params)
    commit('SET_LOADING_PROCESS', { loading: true, name: 'archives' })

    return api.webrestore
      .getArchives(params)
      .then(async ({ data, meta }) => {
        commit('SET_ARCHIVES', data)
        commit('SET_ARCHIVES_CURRENT_META', meta)

        await Promise.all(
          data.map(({ name }) => dispatch('fetchArchiveVersionList', name))
        )
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'archives',
        })
      })
  },

  /**
   * fetchArchiveVersionList
   * ? Извлечь список версий архива
   *
   * @param {ArchivesContext} ctx context
   * @param {string} name имя архива
   * @returns {Promise<void>}
   */
  fetchArchiveVersionList: (
    { commit }: ArchivesContext,
    name
  ): Promise<void> => {
    const payload = { backup: name }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'archive-version',
    })

    return api.webrestore
      .getBackupVersions(payload)
      .then(({ data }) =>
        commit('SET_ARCHIVE_VERSIONS', { name, versions: data })
      )
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'archive-version',
        })
      })
  },

  /**
   * deleteArchive
   * ? Удалить архив
   *
   * @param {ArchivesContext} param0 context
   * @param {IBackupDeletePayload} payload параметры запроса удаления архива
   * @returns {Promise<void>}
   */
  deleteArchive: (
    { commit, dispatch, getters, rootGetters }: ArchivesContext,
    payload: IBackupDeletePayload
  ): Promise<void> => {
    const { page, order_by, direction } = getters.currentArchivesMeta
    const params = {
      page,
      order_by,
      direction,
    }

    commit('SET_LOADING_PROCESS', {
      name: 'archive-delete',
      loading: true,
    })

    const { archives } = payload
    const decodedArchiveName = archives ? decodeURIComponent(archives) : ''
    const sessionId =
      decodedArchiveName in rootGetters['session/session']
        ? rootGetters['session/session'][decodedArchiveName]
        : null

    const deleteArchive = () => {
      return api.webrestore
        .deleteUserBackups(payload)
        .then((data) => {
          commit('session/DELETE_SESSION', decodedArchiveName, {
            root: true,
          })

          if (rootGetters['nav/currentRouteName'] === 'archives') {
            return dispatch('fetchArchives', params)
          }

          return data
        })
        .finally(() =>
          commit('SET_LOADING_PROCESS', {
            name: 'archive-delete',
            loading: false,
          })
        )
    }

    if (sessionId) {
      return api.webrestore
        .deleteSession(sessionId)
        .then(() =>
          new Promise((r) => setTimeout(r, 2000)).then(() => deleteArchive())
        )
        .catch((error) => {
          if (error.code === API_ERROR.ENTITY_NOT_FOUND) {
            commit('session/DELETE_SESSION', decodedArchiveName, {
              root: true,
            })

            return dispatch('deleteArchive', payload)
          }

          return Promise.reject(error)
        })
    }

    return deleteArchive()
  },
}
