import type { Route, Dictionary } from 'vue-router/types/router'

import router from '@/router'
import { RouteMeta } from 'vue-router'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'

export interface IRoute {
  path: string
  query: Dictionary<string>
}

/**
 * replaceRouteQuery
 * ? Заменить query параметры маршрута
 *
 * @param {RouteMeta} query параметры
 * @returns {Promise<Route | void>} текущие параметры
 */
export const replaceRouteQuery = (query: RouteMeta): Promise<Route | void> => {
  return router.replace({ query }).catch((error) => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.resolve()
    }

    return Promise.reject(error)
  })
}

/**
 * replaceRoutePath
 * ? Заменить путь маршрута
 *
 * @param {string} path путь
 * @returns {Promise<Route | void>} текущий путь
 */
export const replaceRoutePath = (path: string): Promise<Route | void> => {
  return router.replace({ path }).catch((error) => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.resolve()
    }

    return Promise.reject(error)
  })
}

/**
 * replaceRoute
 * ? Заменить путь и параметры маршрута
 *
 * @param {IRoute} route путь и параметры маршрута
 * @returns {Promise<Route | void>} текущий маршрут
 */
export const replaceRoute = ({
  path,
  query,
}: IRoute): Promise<Route | void> => {
  return router.replace({ path, query }).catch((error) => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.resolve()
    }

    return Promise.reject(error)
  })
}

/**
 * pushRouteName
 * ? Перейти на переданный маршрут
 *
 * @param {string} name название маршрута
 * @returns {Promise<Route | void>} текущий путь
 */
export const pushRouteName = (name: string): Promise<Route | void> => {
  return router.push({ name }).catch((error) => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.resolve()
    }

    return Promise.reject(error)
  })
}
